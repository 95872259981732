export default {
  retrieveInfo: jest.fn(),
  retrieveAddress: jest.fn(),
  retrieveHousfyAgent: jest.fn(),
  retrieveAccountingYears: jest.fn(),
  retrieveAccountingInAYear: jest.fn(),
  retrieveLeasings: jest.fn(),
  retrievePropertyInfo: jest.fn(),
  retrievePropertyHistory: jest.fn(),
};
